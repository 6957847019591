import React, { useState } from "react";
import MultiSelect from "react-multi-select-component";
import Card from "../Card";
import { FaRegWindowClose } from "react-icons/fa";

const options = [
  { label: "Fin de la pobreza (Objetivo 1)", value: "1" },
  { label: "Hambre cero (Objetivo 2)", value: "2" },
  { label: "Salud y bienestar (Objetivo 3)", value: "3" },
  { label: "Educación de calidad (Objetivo 4)", value: "4" },
  {
    label: "Igualdad de género y empoderamiento de la mujer (Objetivo 5)",
    value: "5",
  },
  { label: "Agua limpia y saneamiento (Objetivo 6)", value: "6" },
  {
    label: "Energía asequible y no contaminante (Objetivo 7)",
    value: "7",
  },
  {
    label: "Trabajo decente y crecimiento económico (Objetivo 8)",
    value: "8",
  },
  {
    label: "Industria, innovación e infraestructura (Objetivo 9)",
    value: "9",
  },
  {
    label:
      "Reducción de las desigualdades entre países y dentro de ellos (Objetivo 10)",
    value: "10",
  },
  {
    label: "Ciudades y comunidades sostenibles (Objetivo 11)",
    value: "11",
  },
  {
    label: "Producción y consumo responsables (Objetivo 12)",
    value: "12",
  },
  { label: " Acción por el clima (Objetivo 13)", value: "13" },
  { label: "Vida submarina (Objetivo 14)", value: "14" },
  { label: "Vida de ecosistemas terrestres (Objetivo 15)", value: "15" },
  {
    label: " Paz, justicia e instituciones sólidas (Objetivo 16)",
    value: "16",
  },
  {
    label: "Alianzas para lograr los objetivos (Objetivo 17)",
    value: "17",
  },
];

const optionsType = [
  {
    label: "Organización de la Sociedad Civil / ONG",
    value: "1",
  },
  {
    label: "Economía social",
    value: "2",
  },
  {
    label: "Cooperativa de Trabajo",
    value: "9",
  },
];
const Filter = ({ closeModal, search }) => {
  const [selected, setSelected] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [name, setName] = useState();

  const saveName = (e) => {
    setName(e.target.value ? e.target.value : null);
  };
  const dispatchSearch = () => {
    const newSearch = {};

    if (selected.length !== 0) {
      const ods = selected.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.value + ",";
      }, []);

      newSearch.ods = ods.slice(0, -1);
    }
    if (name) {
      newSearch.name = name;
    }

    if (selectedType.length !== 0) {
      const entityType = selectedType.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.value + ",";
      }, []);

      newSearch.entityType = entityType.slice(0, -1);
    }

    search(newSearch);
  };

  return (
    <div className="modal-content">
      <Card
        title={
          <div className="d-flex align-items-center justify-content-between">
            <h5> Buscador </h5>
            <button
              type="button"
              className="close"
              onClick={closeModal}
              aria-label="Close"
            >
              <FaRegWindowClose />
            </button>
          </div>
        }
        body={
          <>
            <div class="form-group">
              <label for="formGroupExampleInput">Nombre</label>
              <input
                type="text"
                class="form-control"
                id="formGroupExampleInput"
                placeholder="Ingrese el nombre de la organización"
                onChange={saveName}
              />
            </div>
            <div class="form-group">
              <label for="formGroupExampleInput">
                Objetivos de desarrollo sostenible
              </label>
              <MultiSelect
                options={options}
                value={selected}
                onChange={setSelected}
                labelledBy={"Select"}
              />
            </div>
            <div class="form-group">
              <label for="formGroupExampleInput">Tipo de organización</label>
              <MultiSelect
                options={optionsType}
                value={selectedType}
                onChange={setSelectedType}
                labelledBy={"Select"}
              />
            </div>
          </>
        }
        footer={
          <div className="d-flex justify-content-between flex-row-reverse">
            <button
              type="button"
              className="btn btn-outline-info"
              onClick={closeModal}
            >
              Cancelar
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={dispatchSearch}
            >
              Buscar
            </button>
          </div>
        }
      />
    </div>
  );
};

export default Filter;
