import { call, put, takeLatest } from "redux-saga/effects";
import { apiCall } from "../../../utils/api";

export const actionTypes = {
  GetEntitiesStart: "[ENTITIES] GET START",
  GetEntitiesComplete: "[ENTITIES] GET COMPLETE",
  GetEntitiesError: "[ENTITIES] GET ERROR",
};

const initialAuthState = {
  loading: false,
  entities: null,
  error: "",
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.GetEntitiesStart: {
      return { ...state, loading: true, entities: null };
    }
    case actionTypes.GetEntitiesComplete: {
      const entities = action.results.data;
      return { ...state, loading: false, entities };
    }
    case actionTypes.GetEntitiesError: {
      const { error } = action;
      return {
        ...state,
        loading: false,
        entities: null,
        error,
      };
    }
    default:
      return state;
  }
};

export const actions = {
  getEntities: (payload) => {
    return {
      type: actionTypes.GetEntitiesStart,
      payload,
    };
  },
};
// Watchers

export function* getEntitiesState({ payload }) {
  try {
    const setPage = payload.pages ? `&page[number]=${payload.pages}` : "";
    const setName = payload.name ? `&filter[name]=${payload.name}` : "";
    const setOds = payload.ods ? `&filter[inSdgs]=${payload.ods}` : "";
    const setIsRed = payload.isRed
      ? `&filter[isOwnerNetworks]=${payload.isRed}`
      : "&filter[isOwnerNetworks]=0";

    const entityTypes = payload.entityType
      ? `filter[inTypes]=${payload.entityType}`
      : "filter[inTypes]=1,2,9";
    // const entityTypes = "1,2,3,4,5,6,7,8,10,9";
    const results = yield call(
      apiCall,
      `entity?${entityTypes}${setName}${setIsRed}${setOds}&include=networks${setPage}&sort=-updated_at`,
      null,
      "GET"
    );
    yield put({ type: actionTypes.GetEntitiesComplete, results });
  } catch (error) {
    yield put({ type: actionTypes.GetEntitiesError, error });
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GetEntitiesStart, getEntitiesState);
}
