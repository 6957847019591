import React from "react";
import Logo from "./logo.png";
import Fonselp from "./fonselp.png";
import styled from "styled-components";
import { Link } from "react-router-dom";
const ImgF = styled.img`
  height: 50px;
`;
const ImgV = styled.img`
  height: 50px;
`;

const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light ">
      <div className="container flex-row justify-content-between align-bottom">
        <Link to="/">
          <ImgV src={Logo} />
        </Link>
        <div className="d-flex justify-content-center  align-items-center">
          <a
            className="mr-2"
            href="https://www.valida.ong/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sitio Institucional
          </a>
          |
          <a
            className="mx-2"
            href="https://app.fonselp.com/sumarse"
            target="_blank"
            rel="noopener noreferrer"
          >
            Registrate
          </a>
          |
          <a
            className="ml-2"
            href="https://app.fonselp.com/login"
            target="_blank"
            rel="noopener noreferrer"
          >
            Iniciar sesión
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Header;
