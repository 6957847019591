import React from "react";
import { Link } from "react-router-dom";
import {
  FaGlobeAmericas,
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";

import Card from "../../Base/Card";
import NetworkLogo from "../../Base/NetworkLogo";

import "./entity.scss";

const Entity = ({ entity, id }) => {
  const limitDescription = 140;
  const desc =
    entity.about_us.length >= limitDescription
      ? entity.about_us.slice(0, limitDescription) + "..."
      : entity.about_us;

  const showNetworks = () => {
    return (
      entity.networks &&
      entity.networks.map(
        (network) =>
          network.is_visible && (
            <NetworkLogo
              key={`OverlayTrigger-${network.id}-${id}`}
              network={network}
            ></NetworkLogo>
          )
      )
    );
  };

  return (
    <Card
      body={
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <h5>{entity.type.name}</h5>
            <div>
              {entity.web_profile && (
                <a
                  className="socialNetworks"
                  href={entity.web_profile}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaGlobeAmericas />
                </a>
              )}

              {entity.facebook_profile && (
                <a
                  className="socialNetworks"
                  href={entity.facebook_profile}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook />
                </a>
              )}
              {entity.instagram_profile && (
                <a
                  className="socialNetworks"
                  href={entity.instagram_profile}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram />
                </a>
              )}
              {entity.facebook_profile && (
                <a
                  className="socialNetworks"
                  href={entity.facebook_profile}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn />
                </a>
              )}
              {entity.twitter_profile && (
                <a
                  className="socialNetworks"
                  href={entity.twitter_profile}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitter />
                </a>
              )}
            </div>
          </div>
          <Link to={`info/${entity.id}`}>
            <h4>
              <strong>{entity.bussiness_name}</strong>
            </h4>
          </Link>
          {desc && (
            <>
              <hr />
              <p>{desc}</p>
            </>
          )}

          <hr />
          <h6 className="text-muted">Organización validada por</h6>
          <div className="d-flex align-items-center flex-wrap">
            {showNetworks()}
          </div>
        </div>
      }
      footer={
        <div className="d-flex justify-content-between flex-row-reverse">
          <Link to={`/info/${entity.id}`} className="btn btn-outline-info">
            Ver mas
          </Link>
          {/* <button type="button" className="btn btn-success">
            Donar
          </button> */}
        </div>
      }
    ></Card>
  );
};
export default Entity;
